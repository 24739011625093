import React from 'react'

import Container from '../Container'
import { StyledFooter, StyledFlex, StyledBox } from './index.styles'
import VNGLogo from '../../images/svg/vng-logo.svg'

const Footer = () => (
  <StyledFooter>
    <Container>
      <StyledFlex>
        <StyledBox textAlign="left">
          <ul>
            <li><a href="https://www.commonground.nl">Common Ground</a></li>
            <li><a href="https://www.vngrealisatie.nl/over-vngrealisatie">Over VNG Realisatie</a></li>
            <li><a href="https://commonground.nl/events">Agenda</a></li>
            <li><a href="https://workshops.this-way.nl/">Workshops This Way Cartografie</a></li>
          </ul>
        </StyledBox>
        <StyledBox textAlign="right">
	  <VNGLogo />
        </StyledBox>
      </StyledFlex>
    </Container>
  </StyledFooter>
)

export default Footer
